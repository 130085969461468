import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const EstudoPage = lazy(() =>
	import("./estudo/EstudoPage")
);

const NotificacaoPage = lazy(() =>
	import("./notificacao/NotificacaoPage")
);

const ProjetoPage = lazy(() =>
	import("./projeto/ProjetoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/projeto/:id/estudo" component={EstudoPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/notificacao" component={NotificacaoPage} />
				<Route path="/projeto" component={ProjetoPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/usuario" component={UsuarioPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
