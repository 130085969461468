export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "usuario",
				bullet: "dot"
			},
			{
				title: "Projetos",
				root: true,
				icon: "fas fa-tasks",
				page: "projeto",
				bullet: "dot"
			},
			{
				title: "Notificações",
				root: true,
				icon: "fas fa-bell",
				page: "notificacao",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Atividades realizadas",
						bullet: "dot",
						page: "relatorio/relatorio01"
					}
				]
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
